<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Transmittal Number</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Transmittal Number"
              v-model="filter.transmittal_number"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Transmittal Date</label>

            <b-form-datepicker
              id="input-large"
              size="lg"
              v-model="filter.date"
              :no-flip="true"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              locale="id"
              placeholder="DD-MM-YYYY"
              reset-button
              reset-value=""
            ></b-form-datepicker>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Company</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Company"
              v-model="filter.company_name"
            ></b-form-input>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button class="btn btn-primary btn-md" @click="onFilter(filter)">
            <i class="flaticon2-search-1"></i> Show
          </button>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <complete-table :data="currentReportTransmittal" :loading="loading">
          <template #header>
            <tr class="text-left">
              <th style="min-width: 250px" class="pl-7">
                <span class="text-dark-75">Company</span>
              </th>
              <th style="min-width: 100px">Subject</th>
              <th style="min-width: 100px">Project Code</th>
              <th style="min-width: 100px">Nickname</th>
              <th style="min-width: 100px">Transmittal Date</th>
              <th style="min-width: 100px">Transmittal Number</th>
              <th style="min-width: 150px">Action</th>
            </tr>
          </template>

          <template #defaultBody="{ item, i }">
            <tr v-bind:key="i">
              <td class="pl-0 ">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.company.name }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.subject }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.project_code }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.project ? item.project.nickname : "-" }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ moment(item.date).format("DD-MM-YYYY") }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.number }}
                </span>
              </td>

              <td class="pl-0 pt-8 text-center">
                <a
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="toTransmittalDetail(item)"
                  title="Transmittal Detail"
                >
                  <i class="flaticon-book"></i>
                </a>
              </td>
            </tr>
          </template>
        </complete-table>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_REPORT_TRANSMITTAL } from "@/core/services/store/report-transmittal.module";
import { mapGetters } from "vuex";
import CompleteTable from "@/view/content/widgets/advance-table/CompleteTable";

export default {
  name: "transmittal-list",
  components: { CompleteTable },
  data() {
    return {
      filter: {
        project_code: null,
        project_nickname: null,
        transmittal_number: null,
        company_name: null,
        date: null,
      },
      currentItem: null,
      options: {
        statuses: [
          { value: null, text: "Choose Status" },
          { value: "open", text: "Open" },
          { value: "close", text: "Close" },
        ],
      },
      data: {
        projects: [
          {
            id: 1,
            code: "LR98123",
            name: "PEMBANGUNAN SISTEM PERSINYALAN",
            status: "Open",
            owner: "BALAI TEKNIK PERKERETAAPIAN",
          },
        ],
      },
      loadingActive: false,
      tableMainProject: true,
    };
  },
  methods: {
    toTransmittalDetail(data) {
      this.$router.push({
        name: "transmittal-detail",
        params: {
          id: data.id,
        },
      });
    },
    onFilter(param) {
      const filter = {
        params: {
          project_code: this.$route.params.id,
          project_nickname:
            param.project_nickname === null ? "" : param.project_nickname,
          transmittal_number:
            param.transmittal_number === null ? "" : param.transmittal_number,
          company_name: param.company_name === null ? "" : param.company_name,
          date: param.date === null ? "" : param.date,
        },
      };
      this.$store.dispatch(GET_REPORT_TRANSMITTAL, filter);
    },
  },
  async created() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Transmittal List" }]);
    this.onFilter(this.filter);

    this.loadingActive = true;
    this.tableMainProject = false;
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentMenus",
      "currentReportTransmittal",
      "currentLoadingTransmittalReport",
    ]),

    loading() {
      return this.currentLoadingTransmittalReport;
    },
  },
};
</script>

<style></style>
